const COINBASE_URL = 'https://api.coinbase.com/v2';
const COINPAPRIKA_URL = 'https://api.coinpaprika.com/v1';
const COINGECKO_URL = 'https://api.coingecko.com/api/v3';
const COINBASE_SUPPORT = [
  'BTC',
  'ETH',
  'LTC',
  'XRP',
  'BCH',
  'BSV',
  'ETC',
  'XLM',
  'LINK',
  'ZRX',
  'BAT',
  'DAI',
  'ZEC',
];

function getCoinpaprikaPrice(coinId, currency) {
  return fetch(`${COINPAPRIKA_URL}/tickers/${coinId}?quotes=${currency}`, {
    cache: 'no-cache',
  })
    .then(res => res.json())
    .then(res => {
      const quote = res.quotes[currency];
      return {
        price: quote.price,
        change_24h: quote.percent_change_24h,
        last_updated_at: res.last_updated,
      };
    });
}

function getCoingeckoPrice(coinId, currency) {
  return fetch(
    `${COINGECKO_URL}/simple/price?ids=${coinId}&vs_currencies=${currency}&include_24hr_change=true&include_last_updated_at=true`,
    {
      cache: 'no-cache',
    }
  )
    .then(res => res.json())
    .then(res => {
      const quote = res[coinId];
      const currencyKey = currency.toLowerCase();
      return {
        price: quote[currencyKey],
        change_24h: quote[`${currencyKey}_24h_change`],
        last_updated_at: quote.last_updated_at,
      };
    });
}

function getCoinbasePrice(ticker, currency) {
  return fetch(`${COINBASE_URL}/prices/${ticker}-${currency}/spot`, {
    cache: 'no-cache',
  })
    .then(res => res.json())
    .then(res => ({
      price: res.data.amount,
    }));
}

export function getPrice(coin, currency = 'USD') {
  if (COINBASE_SUPPORT.includes(coin.ticker)) {
    return getCoinbasePrice(coin.ticker, currency);
  }

  // return getCoingeckoPrice(coin.coingecko_id, currency);
  return getCoinpaprikaPrice(coin.coinpaprika_id, currency);
}

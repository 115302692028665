const Router = {
  getTicker: () => {
    const path = window.location.pathname.replace(/\//g, '');
    return path ? path.toUpperCase() : null;
  },
  setTicker: ticker => {
    const params = window.location.search;
    history.pushState(null, null, `/${ticker.toLowerCase()}${params}`);
  },
  getCurrency: () => {
    const url = new URL(window.location.href);
    const currency = url.searchParams.get('currency');
    return currency && currency.length === 3 ? currency.toUpperCase() : null;
  },
  setCurrency: currency => {
    const path = window.location.pathname;
    history.pushState(null, null, `${path}?currency=${currency.toLowerCase()}`);
  },
};

export default Router;

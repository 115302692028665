const USER_LANG = navigator.language || navigator.userLanguage;

export function formatCurrency(number, currency) {
  return new Intl.NumberFormat(USER_LANG, {
    style: 'currency',
    currency,
  }).format(number);
}

export function updateFavicon(ticker) {
  const favicon = document.querySelector("link[rel='shortcut icon']");
  favicon.href = `/icons/png/${ticker.toLowerCase()}.png`;
}

export function updateTitle(amount, ticker, currency) {
  document.title = `${amount} · ${ticker} to ${currency}`;
}

export function getHiddenProp() {
  var prefixes = ['webkit', 'moz', 'ms', 'o'];

  // if 'hidden' is natively supported just return it
  if ('hidden' in document) return 'hidden';

  // otherwise loop over all the known prefixes until we find one
  for (var i = 0; i < prefixes.length; i++) {
    if (prefixes[i] + 'Hidden' in document) return prefixes[i] + 'Hidden';
  }

  // otherwise it's not supported
  return null;
}

export function isPageHidden(hiddenProp) {
  if (!hiddenProp) return false;
  return document[hiddenProp];
}

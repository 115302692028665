import Router from './Router';
import { getPrice } from './Api';
import {
  formatCurrency,
  updateFavicon,
  updateTitle,
  getHiddenProp,
  isPageHidden,
} from './Utils';

import tickerData from './data/top-coins.json';
import currencyData from './data/fiat-currencies.json';

const DEFAULT_TICKER = 'BTC';
const DEFAULT_CURRENCY = 'USD';
const ACTIVE_INTERVAL = 5000;
const PASSIVE_INTERVAL = 10000;

function init() {
  const body = document.querySelector('body');
  const price = document.querySelector('.quote');
  const title = document.querySelector('#conversion-title');
  const tickerIcon = document.querySelector('#ticker-icon');
  const tickerSelect = document.querySelector('#ticker-select');
  const currencySelect = document.querySelector('#currency-select');

  let ticker = Router.getTicker() || DEFAULT_TICKER;
  let currency = Router.getCurrency() || DEFAULT_CURRENCY;
  let pollingInterval = null;
  let intervalTime = ACTIVE_INTERVAL;

  function updatePrice(amount) {
    price.innerHTML = amount;
  }

  function updateHeader(amount, ticker, currency) {
    title.innerText = `The current price of ${tickerData[ticker].name} (${ticker}) in ${currency}`;
  }

  function loadQuote() {
    const coin = tickerData[ticker];

    getPrice(coin, currency).then(quote => {
      const amount = formatCurrency(quote.price, currency);
      updateTitle(amount, ticker, currency);
      updateHeader(amount, ticker, currency);
      updatePrice(amount);
    });
  }

  function startPolling() {
    loadQuote();
    clearInterval(pollingInterval);
    pollingInterval = setInterval(loadQuote, intervalTime);
  }

  function setCurrencyOptions() {
    const popularCurrencies = ['USD', 'EUR', 'GBP', 'JPY', 'AUD', 'CNY', 'CHF'];
    const sortedCurrencies = currencyData.currencies.slice().sort();
    const otherCurrencies = sortedCurrencies.filter(
      c => !popularCurrencies.includes(c)
    );

    let options = '';
    popularCurrencies.concat(otherCurrencies).forEach(currency => {
      options += `<option value=${currency}>${currency}</option>`;
    });

    currencySelect.innerHTML = options;
    currencySelect.value = currency;
  }

  function setTickerOptions() {
    let options = '';
    Object.keys(tickerData).forEach(ticker => {
      options += `<option value=${ticker}>${ticker}</option>`;
    });

    tickerSelect.innerHTML = options;
    tickerSelect.value = currency;
  }

  function initializeCurrencySelect() {
    setCurrencyOptions();
    currencySelect.addEventListener('change', function(e) {
      currency = e.target.value;
      startPolling();
      Router.setCurrency(currency);
    });
  }

  function initializeTickerSelect() {
    setTickerOptions();
    updateCryptoImages(ticker);
    tickerSelect.value = ticker;
    tickerSelect.addEventListener('change', function(e) {
      ticker = e.target.value;
      updateCryptoImages(ticker);
      startPolling();
      Router.setTicker(ticker);
    });
  }

  function updateCryptoImages(ticker) {
    updateFavicon(ticker);
    tickerIcon.src = `/icons/svg/${ticker.toLowerCase()}.svg`;
  }

  function watchPageVisibility() {
    const visProp = getHiddenProp();
    if (visProp) {
      const evtname = visProp.replace(/[H|h]idden/, '') + 'visibilitychange';
      document.addEventListener(evtname, () => {
        if (isPageHidden(visProp)) {
          intervalTime = PASSIVE_INTERVAL;
        } else {
          intervalTime = ACTIVE_INTERVAL;
        }

        startPolling();
      });
    }
  }

  function initializePages() {
    const toggle = document.querySelector('#show-currencies');
    const close = document.querySelector('.close-page');
    const pageCurrencies = document.querySelector('#page-currencies');
    const tickerList = document.querySelector('#supported-tickers');

    let links = '';

    Object.keys(tickerData).forEach(ticker => {
      const coin = tickerData[ticker];
      links += `<li><a href="/${coin['ticker_slug']}" title="${coin.name} (${
        coin.ticker
      })">${coin.name}</a></li>`;
    });
    tickerList.innerHTML = links;

    toggle.addEventListener('click', function(e) {
      pageCurrencies.classList.toggle('is-open');
    });

    close.addEventListener('click', function(e) {
      pageCurrencies.classList.toggle('is-open');
    });
  }

  // initialize everything
  startPolling();
  initializeTickerSelect();
  initializeCurrencySelect();
  watchPageVisibility();
  initializePages();
}

init();
